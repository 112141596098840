body {
    background-color: #fff;
}


.slider-outer {
    margin-top: 2.7rem;
}
.carousel-inner img {
    width: 1145px;
     /* height: 600px;  */
}

#custCarousel .carousel-indicators {
    position: static;
    margin-top: 20px
}

#custCarousel .carousel-indicators>li {
    width: 100px
}

#custCarousel .carousel-indicators li img {
    display: block;
    opacity: 0.5
}

#custCarousel .carousel-indicators li.active img {
    opacity: 1
}

#custCarousel .carousel-indicators li:hover img {
    opacity: 0.75
}

.carousel-item img {
    width: 100%
}

@media (min-width: 300px) and (max-width: 900px) {
    .carousel-inner img {
        height: 350px; 
        /* margin-top: 6.5em; */
    }
    .slider-outer {
        margin-top: 6.5rem;
    }
}

@media (max-width: 1450px){
    .mobile_view_sider{
      /* margin-top: 4.7rem !important; */
      margin-top: 2.7rem !important;
    }
  }

@media (max-width: 900px){
    .mobile_view_sider{
    margin-top: -2rem !important;
    }
}

@media (max-width: 767px){
    .mobile_view_sider{
    margin-top: -1rem !important;
    }
    .pt-sm-mob-5{
        padding-top: 3rem;
    }
}

@media (min-width: 1450px){
    .mobile_view_sider{
      margin-top: 5rem !important;
    }
  }

  

  @media (max-width: 450px){
    .mobile_view_sider{
    /* margin-top: -2rem !important; */
    margin-top: -4rem !important;
    }
}