* {
  scroll-behavior: smooth;
}
body {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  scroll-behavior: smooth;
}
img {
  width: 100%;
}
a {
  color: inherit;
  text-decoration: none;
}
a:hover {
  color: inherit;
  text-decoration: none;
}
h1 {
  font-size: 28px;
}
ul {
  list-style: none;
}
button,
input,
select,
textarea {
  outline: 0 !important;
}
/*header css start*/
.t{
  color: #d5d9d8 !important;
}
.t:hover{
  color:#d21e25 !important;
}
header {
  background: black;
  color: #d5d9d8;
  z-index: 5000;
  position: fixed;
  top: 0;
  width: 100%;
}
.close_side {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 500;
}
.slider_image {
  height: 33rem !important;
  width: 100% !important;
  margin-left: -0.25rem;
}
.slidervideo1 {
  width: 80px;
  margin-left: 62.1rem;
}
.slidervideo2 {
  width: 80px;
  margin-left: 62.1rem;
}
.card-body ul {
  line-height: 1.5;
}
.card-header .btn-link {
  font-weight: 400;
  color: #d5d9d8 !important;
  text-decoration: none;
  width: 100%;
  text-align: left;
  outline: 0 !important;
}
.shopBycar{
  padding: 0px 1.25rem; 
  background: none;
}
.shopBycar_item{
  padding: 0 1.25rem;
}
.shopBycar .btn-link{
  color: #000 !important;
  padding-right: 0px;
  transition: all 0.5s ease-in;
}
.CheckShopbycar{
  accent-color: currentcolor;
}
.filter_shopbycar .btn-link i{
  transform: rotate(-90deg);
}
.filter_shopbycar .collapsed .btn-link i{
  transform: rotate(0deg);
}
.btn-link,
.btn-link:focus {
  box-shadow: 0 !important;
}
.btn-link:focus {
  box-shadow: none !important;
}
.brand_logo img {
  height: 6rem;
  width: 8rem;
  /* width: 12rem; */
}
@media (min-width:1600px){
  .brand_logo img{
    height: auto !important;
    /* width: auto !important; */
  width: 12rem;
  }
}
@media (min-width: 1450px){
  .brand_logo img{
    height: auto !important;
    /* width: auto !important; */
  width: 10rem;
  }
}
@media (max-width: 450px){
  .brand_logo img{
    /* height: auto !important; */
    /* width: auto !important; */
  /* width: 8rem !important;
  height: 7rem !important; */
  width: 6rem !important;
  height: 5rem !important;
  }
}
.top_serch {
  position: relative;
}
.top_serch input {
  width: 100%;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000;
  height: 47px;
  padding-left: 20px;
  border-color: #c9c9c9;
  border: 1px solid #c9c9c9;
}
.top_serch img {
  position: absolute;
  right: 15px;
  width: 25px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.side_link img {
  width: 30px;
}
.icn_label {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 3px;
  display: block;
  color: #000;
  text-decoration: none;
}
.top_menu ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 0;
  line-height: 3;
  cursor: pointer;
}
.top_menu ul li {
  font-size: 14px;
  z-index: 50;
  font-weight: 500;
}
.top_menu ul li:hover .sub_menu {
  visibility: visible;
  opacity: 1;
  transform: translateY(0px);
}
.h4{
  font-size: 1rem;
}
.sub_menu {
  position: absolute;
  width: 100%;
  top: 43px;
  left: 0;
  right: 0;
  margin: 0;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s ease-in-out;
  transform: translateY(-10px);
  background: black;
}
.first_ul li {
  padding: 0 10px;
}
.first_ul {
  position: relative;
}
.sub_menu h6 {
  color: #d5d9d8 !important;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.sub_menu ul {
  display: grid;
  grid-template-rows: repeat(6, auto);
  grid-column-gap: 20px;
  grid-auto-flow: column;
  line-height: 2;
}
.sub_menu ul li {
  margin-bottom: 8px;
  color: #d5d9d8 !important;
  padding: 0;
}
.sub_menu ul li:hover {
  color: #d21e25 !important;
  /* text-decoration: underline; */
}
.sub_menu .col-md-3 {
  border-right: 2px dotted #dadada;
}
.list-links li:hover{
  transform: translateX(10px);
}
/*header css end*/
/*categories css start*/
.cat_gory .col {
  height: 188px;
}
.cat_gory .col img {
  height: 100%;
  transition: 0.2s ease-in-out;
}
.cat_gory .col div {
  height: inherit;
  overflow: hidden;
}
.cat_gory .col:hover img {
  transform: scale(1.1);
}
.cat_gory .col h4 {
  text-align: center;
  text-transform: uppercase;
  margin: 15px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
}
/*slider css start*/
.swiper-button-next,
.swiper-button-prev {
  color: #fff !important;
  background: #615f60;
  width: 40px !important;
  /*box-shadow: 0 8px 10px 5px rgb(0 0 0 / 5%);*/
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 20px !important;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: -30px !important;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: -30px !important;
}
.brand_row img {
  height: 55px;
}
/*slider css end*/
.bor_ra p {
  margin-top: 10px;
  margin-bottom: 1rem;
  font-weight: 400;
  color: #000;
  text-decoration: none;
  font-size: 16px;
  text-align: center;
}
.price_box_cut {
  color: #d7314a;
  font-weight: 700;
}
.price_box p {
  font-weight: 500;
}
.price_box_cut del {
  font-size: 12px;
}
.view_btn {
  cursor: pointer;
  background: #d7314a;
  border: 0;
  color: #ffff;
  height: 40px;
  padding: 0 15px;
}
.bor_ra button {
  cursor: pointer;
  background:#d7314a ;
  border: 0;
  color: #ffff;
  height: 40px;
  width: 100%;
  font-size: 12px;
}
.price_box p span {
  color: #d0d0d0;
}
/*categories css end*/
/*image 360 deg start*/
.img_ab {
  position: absolute;
  width: 50%;
  right: 0;
}
.auto-rotate {
  background-color: #ff7675;
  color: #2d3436;
}
.stop.auto-rotate {
  background-color: #d63031;
  color: #dfe6e9;
}
.font_50 {
  font-size: 50px;
  font-weight: 600;
}
.short_text {
  color: #de542d;
  font-weight: 600;
}
/*image 360 deg end*/
/* filter css start */
.modal {
  background: #fff;
  z-index: 70000;
}
.filter ul {
  display: flex;
  line-height: 3;
  margin: 0;
  list-style: none;
  padding: 0;
  border: 1px solid #9999;
}
.filter ul li {
  margin: 0 2rem;
}
.btn.btn-filter {
  border: 1px solid #dee2e6;
}
.filter .fa {
  padding-left: 10px;
}
.filter ul li a {
  font-size: 12px;
  padding: 3px 2px 0px;
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  text-transform: uppercase;
}
.sub_cat input {
  width: 20px;
  height: 20px;
}
.sub_cat {
  border: 1px solid #9999;
  border-top: 0;
}
.inActvDis{
  display: none;
}
.sub_sp {
  font-size: 14px;
  margin-left: 32px;
}
.sub_sp1 {
  margin-left: 25px;
  color: #000000;
  font-size: 14px;
}
/* filter css and */
/*products details css start*/
.details_page .swiper-slide {
  border: 1px solid #e1e1e1;
}
.write_rat span {
  color: #999;
  text-decoration: underline;
  font-size: 11px;
}
.det_text p {
  font-size: 14px;
}
.slider_b_n {
  position: absolute;
  right: 4px !important;
  top: 42%;
}
.slider_b_p {
  position: absolute;
  left: 4px !important;
  top: 42%;
}
.stok {
  color: #000;
}
.old_price {
  color: red;
}
.old_price del {
  font-weight: 500;
  font-size: 14px;
}
.special_price {
  color: #212121;
  font-weight: 500;
  font-size: 15px;
}
.deal_percent {
  margin-left: 12px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  vertical-align: middle;
  letter-spacing: -0.2px;
}
.deal_percent span {
  background: #1f91d2;
  border: 1px solid #1f91d2;
  padding: 3px 10px;
  border-radius: 20px;
}
.price_label {
  font-weight: 500;
  font-size: 18px;
  color: #212121;
}
.taxnote {
  font-size: 12px !important;
  padding-top: 2px;
  color: #000 !important;
}
.qty-block {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.qty-block input {
  padding: 7px 10px;
  border: solid 1px #c95e16;
  color: #999;
  outline: none;
  line-height: normal;
  border: 1px solid #555 !important;
  padding: 10px 10px;
  margin: 0;
  width: 50px !important;
  height: 42px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  outline: 0;
  text-align: center;
}
.cart-plus-minus .btn.cart-plus-1 {
  border-left: 0;
  border-radius: 0 5px 5px 0;
  margin-left: -1.37em;
}
.qty-block .min-qty {
  font-size: 11px;
  color: #737373;
  font-weight: 400;
}
.qty-block label {
  margin: 0;
  font-size: 14px;
  font-weight: 700;
}
.cart-plus-minus {
  display: flex;
  flex-direction: row;
  margin: 0 5px;
}
.cart-plus-minus .btn.cart-minus-1 {
  border-right: 0;
  border-radius: 5px 0 0 5px;
}
.cart-plus-minus .btn {
  width: 30px;
  height: 42px;
  border: 1px solid #555;
  cursor: pointer;
  outline: none;
  font-size: 18px;
  font-weight: 600;
  color: #323232;
  text-align: center;
  line-height: 42px;
  padding: 0;
  background: #d7314a;
}
.add_btn {
  width: 100%;
  background: #de542d;
  border: 0;
  color: #fff;
  height: 44px;
  box-shadow: 0 8px 10px -5px rgb(0 0 0 / 5%);
}
.pro_btn {
  width: 100%;
  background: #616161;
  border: 0;
  color: #fff;
  height: 44px;
  box-shadow: 0 8px 10px -5px rgb(0 0 0 / 5%);
}
.text_uper {
  text-transform: uppercase;
  width: 100%;
  font-weight: 500;
  color: #323232;
}
.pos_sti {
  position: sticky;
  top: 0;
}
/*specification css start*/
.data-table,
tr,
td,
th {
  border: 1px solid #e1e1e1;
  border-collapse: collapse;
}
.data-table tr:nth-child(even) {
  background: #f1f1f1;
}
.inActvMOd{
  display: none;
}
/*specification css end*/
/*products details css end*/
/*login css start*/
h2.title {
  position: relative;
}
h2.title::after {
  width: 30px;
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  border-bottom: 3px solid #c5a981;
  margin-left: -15px;
  margin-top: 7px;
}
.gry_col {
  color: #888888;
}
.login_sec input {
  border: 1px solid #ddd;
  background: #fff;
  font-family: "Roboto", sans-serif;
  height: 35px;
  line-height: 35px;
  width: 100%;
  font-size: 14px;
  padding: 0 9px;
  vertical-align: baseline;
  outline: 0 !important;
}
a.knw_btn {
  /* background: #ff9501; */
  height: 44px;
  border: none;
  color: #fff;
  padding: 0 13px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/*login css end*/
/*cart css start*/
.cart_table th,
td {
  border: 0;
}
.cart_table {
  width: 100%;
}
.cart_table img {
  width: 100px;
}
.cart_table th {
  font-size: 14px;
  font-weight: 500;
}
.side_cart td {
  line-height: 3;
  padding-left: 15px;
  padding-right: 10px;
}
.cart-plus-minus #myform {
  padding: 5px;
  margin: 2%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.cart-plus-minus.qty {
  width: 40px;
  height: 25px;
  text-align: center;
  margin: 0 10px;
}
/* .cart-plus-minus.qtyplus { width:25px; height:25px;}
.cart-plus-minus .qtyminus { width:25px; height:25px;} */
.cart-plus-minus.checkout_colm input[type="email"],
input[type="text"],
input[type="number"],
#registeremail,
select, #vc1, #vc2 {
  font-size: 14px !important;
  padding: 0 !important;
  margin-bottom: 0 !important;
  border: 1px solid #c1c1c1;
  text-align: center;
  margin-right: 1.5rem;
}
.cart-plus-minus input.qtyminus.minus {
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cart-plus-minus input.qtyplus.plus {
  margin-left: -1.50em;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.p_qty {
  padding: 0 !important;
  background-color: #d7314a;
}
.q_input {
  height: 32px !important;
}
/*cart css end*/
/*checkout css start*/
.method_span {
  display: inline-flex;
  background: #de542d;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-right: 10px;
}
.checkout_colm input[type="email"],
input[type="text"],
input[type="number"],
select {
  width: 100%;
  height: 40px;
  font-size: 14px;
  padding: 0 15px;
  margin-bottom: 1rem;
  border: 1px solid #c1c1c1;
}
sub {
  bottom: 0;
  color: red;
}
.checkout_colm textarea {
  width: 100%;
  border: 1px solid #c1c1c1;
}
.checkout_colm label {
  font-size: 14px;
}
.checkout_colm {
  line-height: 2;
}
.meth_pay {
  border: 1px solid #c1c1c1;
}
.meth_pay .col-9 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.meth_pay .col-2 {
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: right;
}
.meth_pay .col-1 {
  background: #edeee6;
  display: flex;
  align-items: center;
  justify-content: center;
}
.meth_pay p {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #000;
  font-weight: 600;
  margin-bottom: 0;
}
.f_button1 button {
  background-color: #de542d;
  color: #fff !important;
  width: 135px !important;
  height: 53px;
  font-size: 17px;
  font-family: "FontAwesome";
  font-weight: 400;
}
.f_button2 button {
  background-color: #212529;
  color: #fff !important;
  width: 135px !important;
  height: 53px;
  font-size: 17px;
  font-family: "FontAwesome";
  font-weight: 400;
}
/*checkout css end*/
/*wishlist css start*/
.remove_wish {
  width: 25px;
  position: absolute;
  right: 15px;
  z-index: 20;
  top: 0;
  /* background: #ff9501; */
}
/*wishlist css end*/
/*footer css start*/
.footer {
  background: black;
  color: #d5d9d8;
  padding: 15px 0;
}
.footer h5 {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-bottom: 22px;
  position: relative;
  margin-top: 0;
  color: #d5d9d8;
}
.footer h4 b {
  color: #d5d9d8;
}
.footer p {
  font-size: 12px;
  color: #d5d9d8;
}
.footer ul li {
  margin-bottom: 4px;
  font-size: 14px;
}
.footer ul li a {
  color: #d5d9d8;
}
.footer ul li a:hover {
  color: #d21e25;
}
.footer input {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000;
  height: 40px;
  padding-left: 20px;
  border: 1px solid #c9c9c9;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 83%;
}
footer i {
  font-size: 26px !important;
}
.footer .footer-header {
  color: white;
  font-size: 16px;
  font-family: unset;
  margin-top:-16px ;
  /* text-transform: uppercase; */
  padding-bottom: 8px;
  /* margin-bottom: 30px; */
}
.under {
  text-decoration: underline;
  text-decoration-color: rgb(235, 11, 11);
  text-decoration-thickness: 3px;
}
.pl-0 li>a:hover:before, .pl-0 li>a:focus:before, .pl-0 li.active>a:before {
  opacity: 1;
  visibility: visible;
}
.pl-0 li>a:before {
  content: "\f105";
  position: absolute;
  left: 0px;
  font-family: FontAwesome;
  -webkit-transform: translateX(-10px);
  -ms-transform: translateX(-10px);
  transform: translateX(-10px);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}
.pl-0 li:hover{
  color:#000;
  transform: translateX(5px);
}
.tStyle{
  background:white;
  color:#d21e25;
}
/* footer css end */
.popupboxInHeader {
  top: 11rem;
  z-index: 500;
  /* left: 20rem; */
  right:1rem;
  font-size: 1.2rem;
}
.popupboxInPromoCode {
  top: 13rem;
  z-index: 500;
  left: 3rem;
  font-size: 1.2rem;
  position: fixed;
}
/* Register */
input[type="date"]::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  display: none;
  text-align: center;
}
input[type="date"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}
.store-dropdown-menu {
  width: 17rem;
  margin-top: 3rem;
  line-height: 1.2;
  /* position: relative !important; */
  transform: translate3d(0px, 0px, 0px) !important;
}
/*media query css start*/
.react-multi-carousel-list ul {
  border: 0px;
}
.slider-img{
  /* height:30rem ; */
}
::-webkit-scrollbar {
  width:0.3125rem;
}
::-webkit-scrollbar-track {
  background-color:white;
}
::-webkit-scrollbar-thumb{
  background:#d21e25;
}
@media (max-width: 767px)
{
  .desktopfilter
  {
    display: none !important;
  }
}
@media (min-width: 300px) and (max-width: 900px) {
  header {
    top: 0;
    box-shadow: 0px 6px 20px rgb(0 0 0 / 12%);
  }
  .heading-div{
    /* margin-top: 3rem; */
  }
  .brand_logo img {
    height: 8rem;
    width: 10rem;
  }
  .cat_gory .col {
    height: auto;
    width: 50% !important;
    flex-basis: auto;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 50%;
    margin-bottom: 15px;
  }
  .slider_image {
    height: 15rem !important;
    width: 100% !important;
    margin-left: -0.25rem;
  }
  .popupboxInPromoCode {
    top: 10rem;
    z-index: 500;
    left: 1rem;
    font-size: 1rem;
    position: fixed;
  }
  .popupboxInHeader {
    top: 7rem;
    z-index: 500;
    left: 1rem;
    font-size: 1rem;
  }
  .brand_logo {
    display: flex;
    justify-content: center;
  }
  .side_bar {
    position: fixed;
    left: 0;
    top: 0;
    background: #000;
    z-index: 5000;
    height: 100%;
    width: 100%;
    transform: translateX(-900px);
    transition: 0.5s ease-in-out;
    overflow: auto;
  }
  .side_filter {
    position: fixed;
    left: 0;
    top: 0;
    background: #000;
    z-index: 9000;
    height: 100%;
    width: 100%;
    /* transform: translateX(0px); */
    transform: translateX(900px);
    transition: 0.5s ease-in-out;
    overflow: auto;
  }
.slider-img{
   height: 21rem !important;
}
  .first_ul {
    display: block !important;
  }
  .slidervideo1 {
    width: 60px;
    margin-left: 62.1rem;
  }
  .slidervideo2 {
    width: 60px;
    margin-left: 62.1rem;
  }
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: 10px !important;
  }
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: 10px !important;
  }
  .mar_15 {
    margin: 15px;
  }
  .footer h4 {
    text-align: center;
  }
  footer .view_btn {
    width: 134px;
  }
  .top_menu ul li {
    position: relative;
    /* border-bottom: 1px solid #dadada; */
  }
  .sub_menu {
    position: relative;
    top: 0;
    visibility: visible;
    opacity: 1;
    display: none;
  }
  .top_menu ul li::after {
    content: "";
    position: absolute;
    display: inline-block;
    height: 10px;
    width: 10px;
    /* border-color: #74aa62;
    border-style: solid;
    border-width: 1px 1px 0 0; */
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 50%;
    margin-top: -5px;
    right: 15px;
  }
  .sub_menu .col-md-3 {
    border: none;
  }
  .top_menu ul {
    /* overflow: scroll; */
    overflow: hidden;
    height: inherit;
  }
  .top_menu ul li:hover .sub_menu {
    display: block;
  }
  .top_menu ul li:hover::after {
    transform: rotate(134deg);
  }
  .mob_btn {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 500;
  }
  .add_btn {
    margin-bottom: 1rem;
  }
  .cart_table tr {
    /*display: block;*/
    position: relative;
  }
  .cart_table th {
    display: block;
    position: relative;
  }
  .cart_table td {
    display: block;
    position: relative;
  }
  .data-table,
  td {
    padding-left: 15px;
    padding-right: 15px;
    width: 100% !important;
  }
  .cart_table img {
    width: 50%;
  }
  .cart_table .data-table,
  td.pl-1 {
    right: 0;
    top: 15px;
    position: absolute;
    width: auto !important;
  }
  .side_cart tr {
    /* display: flex; */
  }
  .not_show {
    display: none;
  }
  .store-dropdown-menu {
    line-height: 1.2;
    width: 15rem;
    margin-top: 3rem;
    transform: translate3d(0px, 0px, 0px) !important;
  }
}
.cart-count {
  position: absolute;
  /* right: 5px; */
  /* top: 1px; */
  /* margin-left: 1rem; */
  margin-left: 0.9rem;
    margin-top: -5px;
  text-align: center;
  /* z-index: 1; */
  background-color: #d7314a;
  /* padding: 1px; */
  width: 15px;
  height: 15px;
  /* height: 10px; */
  border-radius: 50%;
  color: #fff;
  font-size: 0.8rem;
  text-align: center;
  line-height: 15px;
}
.myDiv{
  width: 100%;
  height: 8vh;
}
.c_btn{
  background: #d5d9d8;
  color: black;
}
.c_btn:hover{
  color:#d21e25 !important;
}
.c_btn2{
  background: #000 !important;
  color: #d5d9d8 !important;
}
.c_btn2:hover{
  color:#d21e25 !important;
}
.c_new{
  background: black !important;
  color: #d5d9d8 !important;
  border:solid #d5d9d8 1px;
}
.c_new3{
  color: #d5d9d8 !important;
  border:solid #d5d9d8 1px;
}
.c_new>collapsed{
  color:#d21e25 !important;
}
.c_new ul li :hover{
  color:#d21e25 !important;
}
.c_new2:hover{
  color:#ffff !important;
  background: black;
}
.c_new2:focus{
  color:#d21e25 !important;
}
.c_new5:hover{
  color:#ffff ;
  background: black;
}
.disc>ul{
  list-style-type:disc;
}
/* ------new header css---------------- */
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
  transition: all 0.5s;
  z-index: 997;
  padding: 20px 0;
}
.header.header-scrolled {
  background: #fff;
  padding: 15px 0;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}
.header .logo {
  line-height: 0;
}
.header .logo img {
  max-height: 40px;
  margin-right: 6px;
}
.header .logo span {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #012970;
  font-family: "Nunito", sans-serif;
  margin-top: 3px;
}
/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}
.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}
.navbar li {
  position: relative;
}
.navbar a, .navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 5px;
  /* font-family: "Nunito", sans-serif; */
  /* font-size: 16px;
  font-weight: 700; */
  color: #d5d9d8;
  white-space: nowrap;
  transition: 0.3s;
}
.navbar a i, .navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}
.navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
  color: #d21e25;
}
.navbar .getstarted, .navbar .getstarted:focus {
  background: #4154f1;
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 4px;
  color: #fff;
}
.navbar .getstarted:hover, .navbar .getstarted:focus:hover {
  color: #fff;
  background: #5969f3;
}
.navbar .dropdown ul {
  display: block;
  position: absolute;
  /* left: 9px; */
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #000;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
  /* overflow-y: auto; */
  /* overflow-y: scroll; */
  /* height:30rem; */
}
.navbar .dropdown ul li {
  min-width: 200px;
}
.navbar .dropdown ul a {
  padding: 0px 20px;
  /* font-size: 15px; */
  text-transform: none;
  /* font-weight: 600; */
}
.navbar .dropdown ul a i {
  /* font-size: 12px; */
}
.navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
  color: #d21e25;
}
.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}
.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}
.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}
@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: 100%;
    /* left: -90%; */
  }
  .navbar .dropdown .dropdown:hover > ul {
    left: 100%;
    /* left: -100%; */
  }
}
@media (max-width: 780px) {
  .mc_lg{
width:100% !important;
  }
}
.mc_lg{
  width:50%;
}
/**
* Mobile Navigation 
*/
/* .mobile-nav-toggle {
  color: #012970;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}
.mobile-nav-toggle.bi-x {
  color: #fff;
}
@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }
  .navbar ul {
    display: none;
  }
}
.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(1, 22, 61, 0.9);
  transition: 0.3s;
}
.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}
.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}
.navbar-mobile a, .navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #012970;
}
.navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
  color: #4154f1;
}
.navbar-mobile .getstarted, .navbar-mobile .getstarted:focus {
  margin: 15px;
}
.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}
.navbar-mobile .dropdown ul li {
  min-width: 200px;
}
.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}
.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}
.navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover > a {
  color: #4154f1;
}
.navbar-mobile .dropdown > .dropdown-active {
  display: block;
} */
/* @media (min-width: 992px) */
/* .col-md-12 { */
/* width:100%; */
/* } */
/*media query css end*/
.accordionsh {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.9s;
}
.active, .accordionsh:hover {
  background-color: #ccc;
}
.accordionsh:after {
  content: '\002B';
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}
/* .active:after {
  content: "\2212";
} */
.panelsh {
  padding: 0 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.panelsh input.form-control{
  height: calc(0.5em + 0.75rem + 2px);
  flex: 0.1 0.2 auto !important;
}
.mob-viewpaddding{
  padding: 2rem !important;
}
@media(max-width:767px){
  .navbar_shopBycar{
      display:flex !important;
    }
}
.input-group{
  align-items: center;
  /* display: flex !important; */
}
.btn:focus{
  box-shadow: none !important;
}
input:focus{
  box-shadow: none !important;
}
.carousel-control-next, .carousel-control-prev {
  height:83% !important;
}
@media(max-width:780px){
  .carousel-control-next, .carousel-control-prev {
    height:70% !important;
  }
}
.carousel.carousel-slider .control-arrow:hover{
  background:none;
}
.carousel .control-prev.control-arrow:before {
  border-right: 15px solid #fff;
}
.carousel .control-next.control-arrow:before {
  border-left: 15px solid #fff;
}
.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  opacity:1;
}
.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
  margin: 0 5px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  content: '';
}
.carousel .control-dots .dot {
  background: #d7314a;
}
.pro_name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
@media (max-width: 900px) {
.not_show {
  display: none;
}
}
.carousel-control-next, .carousel-control-prev {
  height:83% !important;
}
@media(max-width:780px){
  .carousel-control-next, .carousel-control-prev {
    height:70% !important;
  }
}